.Toastify__toast-container {
  max-width: 80%;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: -0.0059em;
  text-align: left;
}

.Toastify__toast-container--top-right {
  margin-left: 20%;
}

.Toastify__toast-theme--colored.Toastify__toast--success {
  background-color: #65b477d9;
  font-size: 16px;
}

.Toastify__toast-theme--colored.Toastify__toast--error {
  background-color: #b34b4a;
  font-size: 16px;
}
